import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex_spacebetween_centered column fp_container" }
const _hoisted_2 = { class: "flex_align_centered column mb-5" }
const _hoisted_3 = { class: "w-100" }
const _hoisted_4 = { class: "flex_centered column mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_feven = _resolveComponent("header-feven")
  const _component_localized_text = _resolveComponent("localized-text")
  const _component_form_field = _resolveComponent("form-field")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_header_feven, { class: "mb-4" }),
      _createVNode(_component_localized_text, {
        localizedKey: "app_InsserisciLaMail",
        text: "Insserisci la mail",
        class: "fev_text5 fw_700"
      })
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_form_field, {
        type: "email",
        modelValue: _ctx.email,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.email = $event)),
        placeholderKey: "app_Email",
        placeholderValue: "Email"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_form_field, {
        type: "checkbox",
        checkboxIsSelected: _ctx.subscribed,
        onValueChanged: _cache[2] || (_cache[2] = ($event: any) => (_ctx.subscribed = $event)),
        labelKey: "app_DesideroRicevereAggiornamentiSulleNuoveFunzionalitaEventiEIniziativeFuturePotraiDisattivareQuestaOpzioniInQualsiasiMomentoEntrandoNelTuoProfilo",
        labelValue: "Desidero ricevere aggiornamenti sulle nuove funzionalità, eventi e iniziative future. Potrai disattivare questa opzioni in qualsiasi momento entrando nel tuo profilo.",
        class: "mt-3"
      }, null, 8, ["checkboxIsSelected"])
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode("button", {
        class: "fev_button bg_brand mb-3",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args))),
        disabled: _ctx.isDisabled
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_Conferma",
          text: "Conferma"
        })
      ], 8, ["disabled"])
    ])
  ]))
}