
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import store from '@/store';
import { AppUserClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import InfoModal from '@/components/modals/infoModal.vue';
import { LoginServices } from '@/services/LoginServices';

@Options({
    components: {
    }
})
export default class InsertCode extends Vue {

    userIdentifier: string = "";
    email: string = "";
    subscribed: boolean = true;

    created() {
        this.userIdentifier = store.state.loggedUser.identifier;
        this.email = store.state.loggedUser.emailAddress;
    }

    get isDisabled(){
        return !this.email;
    }

    next(){
        AppUserClient.updateEmailAndNewsletter(this.email, this.subscribed)
        .then( x => {
            LoginServices.LoginCallback(store.state.loggedUser.token, store.state.loggedUser.refreshToken);
        })
        .catch( ex => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("app_Errore", "Errore"),
                subtitle: ex.Message,
                deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

}
